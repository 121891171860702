import React, { useState, useEffect } from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Component/Navbar";

function AdminEditArticle() {
    const { id } = useParams(); // Mengambil ID dari URL
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [status, setStatus] = useState("published"); // Default status
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({}); // State for errors
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [successMessage, setSuccessMessage] = useState("");
    const [failedMessage, setFailedMessage] = useState("");
    const [modalMessage, setModalMessage] = useState("");

    const navigate = useNavigate();

    // Mengambil data artikel untuk diedit
    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await fetch(`https://api2.lyfe.my.id/articles/${id}`);
                const data = await response.json();
                setTitle(data.title);
                setContent(data.content);
                setStatus(data.status);
                setImage(data.image); 
            } catch (error) {
                console.error("Error fetching article:", error);
            }
        };

        fetchArticle();
    }, [id]);

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        setErrors((prev) => ({ ...prev, image: undefined })); // Clear error on change
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};

       // Validate title
       if (!title) {
        newErrors.title = "Judul tidak boleh kosong";
    } else if (title.length < 1 || title.length > 100) {
        newErrors.title = "Judul harus terdiri dari 1 hingga 100 karakter";
    }

    // Validate content
    if (!content) {
        newErrors.content = "Konten tidak boleh kosong";
    } else if (content.length < 1 || content.length > 5000) {
        newErrors.content = "Konten harus terdiri dari 1 hingga 5000 karakter";
    }

    // Validate image
    // if (!image) {
    //     newErrors.image = "Gambar harus diunggah";
    // }

        // If there are errors, set them and return
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const formData = new FormData();
        formData.append("title", title);
        formData.append("content", content);
        formData.append("status", status);
        formData.append("image", image); // Add image to FormData

        try {
            const response = await fetch(`https://api2.lyfe.my.id/edit-article/${id}`, {
                method: "PUT", // Menggunakan PUT untuk update
                body: formData,
            });

            if (response.ok) {
                setSuccessMessage("Artikel berhasil diubah!");
                // setShowModal(true);
                // Optionally, navigate after a timeout
                setTimeout(() => {
                    navigate("/admin/add-article");
                }, 2000);
            } else {
                const data = await response.json();
                setFailedMessage(data.message || "Gagal mengubah artikel");
                // setShowModal(true);
            }
        } catch (error) {
            console.error("Error:", error);
            setFailedMessage("An error occurred. Please try again.");
            // setShowModal(true);
        }
    };

    const handleCloseModal = () => setShowModal(false);

    return (
        <div>
            <Navbar />
            <Container className="mt-5">
                <h1 className="text-center mb-4">Edit Data Artikel</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formArticleImage">
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            isInvalid={!!errors.image} // Mark as invalid if there's an error
                        />
                        {errors.image && <Form.Control.Feedback type="invalid">{errors.image}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group controlId="formArticleTitle">
                        <Form.Label>Judul</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter article title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setErrors((prev) => ({ ...prev, title: undefined })); // Clear error on change
                            }}
                            isInvalid={!!errors.title}
                        />
                        {errors.title && <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group controlId="formArticleContent">
                        <Form.Label>Konten</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Enter article content"
                            value={content}
                            onChange={(e) => {
                                setContent(e.target.value);
                                setErrors((prev) => ({ ...prev, content: undefined })); // Clear error on change
                            }}
                            isInvalid={!!errors.content}
                        />
                        {errors.content && <Form.Control.Feedback type="invalid">{errors.content}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group controlId="formArticleStatus">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            required
                        >
                            <option value="published">Published</option>
                            <option value="draft">Draft</option>
                            <option value="archived">Archived</option>
                        </Form.Control>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        Simpan Perubahan
                    </Button>
                    {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                    {failedMessage && <p className="text-danger mt-3">{failedMessage}</p>}
                </Form>

                {/* Modal for feedback */}
                {/* <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informasi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Tutup
                        </Button>
                    </Modal.Footer>
                </Modal> */}
            </Container>
        </div>
    );
}

export default AdminEditArticle;
