import React, { useState, useEffect } from "react";
import { Container, Form, Button, Card, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navbar from "../Component/Navbar";

function AdminAddArticle() {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [status, setStatus] = useState("published");
    const [image, setImage] = useState(null);
    const [articles, setArticles] = useState([]);
    const [statusFilter, setStatusFilter] = useState("all");
    const [errors, setErrors] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [failedMessage, setFailedMessage] = useState("");
    const [successDeleteMessage, setSuccessDeleteMessage] = useState("");
    const [failedDeleteMessage, setFailedDeleteMessage] = useState("");
    const [modalMessage, setModalMessage] = useState("");



    const navigate = useNavigate();

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
        setErrors((prev) => ({ ...prev, image: undefined }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};

       // Validate title
        if (!title) {
            newErrors.title = "Judul tidak boleh kosong";
        } else if (title.length < 1 || title.length > 100) {
            newErrors.title = "Judul harus terdiri dari 1 hingga 100 karakter";
        }

        // Validate content
        if (!content) {
            newErrors.content = "Konten tidak boleh kosong";
        } else if (content.length < 1 || content.length > 5000) {
            newErrors.content = "Konten harus terdiri dari 1 hingga 5000 karakter";
        }

        // Validate image
        // if (!image) {
        //     newErrors.image = "Gambar harus diunggah";
        // }

        // If there are errors, set them and return
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const formData = new FormData();
        formData.append("title", title);
        formData.append("content", content);
        formData.append("status", status);
        if (image) {
            formData.append("image", image);
        }

        try {
            const response = await fetch("https://api2.lyfe.my.id/add-article", {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                setSuccessMessage("Artikel berhasil ditambahkan!");
                // setModalShow(true);
                navigate("/admin/add-article");
            } else {
                const data = await response.json();
                setFailedMessage(data.message || "Artikel gagal ditambahkan");
                // setModalShow(true);
            }
        } catch (error) {
            console.error("Error:", error);
            setFailedMessage("An error occurred. Please try again.");
            // setModalShow(true);
        }
    };

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch("https://api2.lyfe.my.id/articles");
                const data = await response.json();
                setArticles(data);
            } catch (error) {
                console.error("Error fetching articles:", error);
            }
        };

        fetchArticles();
    }, []);

    const handleEditClick = (articleId) => {
        navigate(`/edit-article/${articleId}`);
    };

    const handleDeleteClick = async (articleId) => {
        // if (window.confirm("Apakah kamu yakin untuk menghapus artikel ini?")) {
            try {
                const response = await fetch(`https://api2.lyfe.my.id/delete-article/${articleId}`, {
                    method: "DELETE",
                });

                if (response.ok) {
                    setSuccessDeleteMessage("Article berhasil dihapus!");
                    // setModalShow(true);
                    setArticles(articles.filter(article => article.id !== articleId));
                } else {
                    const data = await response.json();
                    setFailedDeleteMessage(data.message || "Gagal menghapus artikel");
                    // setModalShow(true);
                }
            } catch (error) {
                console.error("Error deleting article:", error);
                setFailedDeleteMessage("An error occurred. Please try again.");
                // setModalShow(true);
            }
        // }
    };

    const filteredArticles = statusFilter === "all"
        ? articles
        : articles.filter(article => article.status === statusFilter);

    return (
        <div>
            <Navbar />
            <Container fluid className="mt-4">
                <Row>
                    {/* Left Side: Cards */}
                    <Col md={4}>
                        <div className="article-sidebar">
                            <h3 className="text">Daftar Artikel</h3>

                            <Form.Group as={Col} md="4">
                                <Form.Label>Filter</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                >
                                    <option value="all">Semua</option>
                                    <option value="published">Published</option>
                                    <option value="draft">Draft</option>
                                    <option value="archived">Archived</option>
                                </Form.Control>
                            </Form.Group>
                            {successDeleteMessage && <p className="text-success mt-3">{successDeleteMessage}</p>}
                            {failedDeleteMessage && <p className="text-danger mt-3">{failedDeleteMessage}</p>}

                            <Row className="mt-3">
                                {filteredArticles.map((article) => (
                                    <Col md={6} key={article.id} className="mb-2">
                                        <Card style={cardStyle}>
                                            <Card.Img
                                                variant="top"
                                                src={`data:image/jpeg;base64,${article.image}`}
                                                style={imageStyle}
                                            />
                                            <Card.Body style={cardBodyStyle}>
                                                <Card.Title style={cardTitleStyle}>{article.title}</Card.Title>
                                                <Card.Text style={cardTextStyle}>
                                                    {article.content.substring(0, 100)}...
                                                </Card.Text>
                                            </Card.Body>
                                            <Button
                                                variant="info"
                                                onClick={() => handleEditClick(article.id)}
                                                style={buttonStyle}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="warning"
                                                onClick={() => handleDeleteClick(article.id)}
                                                style={buttonStyle}
                                            >
                                                Hapus
                                            </Button>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>

                    {/* Right Side: Form */}
                    <Col md={8}>
                        <h1 className="text-center mb-4">Tambah Data Artikel</h1>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formArticleImage">
                                <Form.Label>Upload Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                {errors.image && <div className="text-danger">{errors.image}</div>}
                            </Form.Group>
                            <Form.Group controlId="formArticleTitle">
                                <Form.Label>Judul</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter article title"
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setErrors((prev) => ({ ...prev, title: undefined }));
                                    }}
                                />
                                {errors.title && <div className="text-danger">{errors.title}</div>}
                            </Form.Group>
                            <Form.Group controlId="formArticleContent">
                                <Form.Label>Konten</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Enter article content"
                                    value={content}
                                    onChange={(e) => {
                                        setContent(e.target.value);
                                        setErrors((prev) => ({ ...prev, content: undefined }));
                                    }}
                                />
                                {errors.content && <div className="text-danger">{errors.content}</div>}
                            </Form.Group>
                            <Form.Group controlId="formArticleStatus">
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option value="published">Published</option>
                                    <option value="draft">Draft</option>
                                    <option value="archived">Archived</option>
                                </Form.Control>
                            </Form.Group>
                            <Button variant="success" type="submit" className="mt-3">
                                Simpan
                            </Button>
                            {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                            {failedMessage && <p className="text-danger mt-3">{failedMessage}</p>}
                        </Form>
                    </Col>
                </Row>
            </Container>

            {/* Modal for messages */}
            {/* <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
}

// CSS in JS
const cardStyle = {
    width: "150px",
    height: "250px",
    margin: "20px",
    borderRadius: "10px",
};
  
const imageStyle = {
    height: "80px",
    objectFit: "cover",
};
  
const cardBodyStyle = {
    padding: "10px",
    textAlign: "center",
};
  
const cardTitleStyle = {
    fontSize: "14px",
    fontWeight: "bold",
};
  
const cardTextStyle = {
    fontSize: "12px",
};
  
const buttonStyle = {
    fontSize: "12px",
    padding: "5px 10px",
};
  
export default AdminAddArticle;
