import { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import Loginimg from "../assets/images/Group51.png";
import "../assets/customcss/logreg.css";
import AuthContext from "../context/AuthContext";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    // Validate fields
    if (!email) {
      newErrors.email = "Email tidak boleh kosong";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Format email tidak valid";
    } else if (email.length < 5 || email.length > 50) {
      newErrors.email = "Email harus terdiri dari 5 hingga 50 karakter";
    }

    if (!password) {
      newErrors.password = "Password tidak boleh kosong";
    } else if (password.length < 8 || password.length > 20) {
      newErrors.password = "Password harus terdiri dari 8 hingga 20 karakter";
    }
    // If there are errors, set them and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      const response = await fetch("https://api2.lyfe.my.id/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const contentType = response.headers.get("content-type");
      let data;
      if (contentType && contentType.includes("application/json")) {
        data = await response.json();
      } else {
        data = await response.text();
      }
      if (response.ok) {
        login(data.user); // Perform login if successful
        navigate("/"); // Redirect to the main page
      } else {
        setModalMessage(data.message || "Login gagal, coba lagi.");
        // setShowModal(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setModalMessage("Terjadi kesalahan. Silakan coba lagi.");
      // setShowModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Container fluid className="d-flex align-items-center justify-content-center vh-100">
      <Row className="w-100 no-gutters">
        <Col lg={6} className="d-flex justify-content-center align-items-center">
          <img
            src={Loginimg}
            alt="Gambar"
            className="img-fluid"
            style={{ objectFit: "cover", borderRadius: "20px" }}
          />
        </Col>
        <Col lg={6} className="d-flex justify-content-center align-items-center p-3 bg-light">
          <div className="form-container">
            <h1 className="text-center mb-5 text-primary">Sign In</h1>
            <Form onSubmit={handleSubmit} className="m-3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Masukkan Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrors((prev) => ({ ...prev, email: undefined })); // Clear error on change
                  }}
                  className="input-login"
                />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrors((prev) => ({ ...prev, password: undefined })); // Clear error on change
                  }}
                  className="input-login"
                />
                {errors.password && <div className="text-danger">{errors.password}</div>}
              </Form.Group>
              <Form.Group className="mb-3">
                <Link to="/forgot" className="text-dark">Lupa Password?</Link>
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Masuk
              </Button>
            </Form>
            <div className="text-center mt-3">
              <p className="text-primary">
                Tidak punya akun? <Link to="/register">Buat Akun</Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>

      {/* Error Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kesalahan</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Login;
