// import React, { useState } from "react";
// import { Carousel } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { ImArrowUpRight2 } from "react-icons/im";
// import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
// const images = [
//   {
//     src: require("../assets/images/Article_Card1.png"),
//     title: "Peran Teknologi Dalam Gaya Hidup Sehat",
//     link: "/artikel",
//   },
//   {
//     src: require("../assets/images/artikel3.jpeg"),
//     title: "Gaya Hidup Sehat Tidak Harus Mahal",
//     link: "/image2",
//   },
//   {
//     src: require("../assets/images/artikel1.png"),
//     title: "Kebiasaaan Olahraga Mudah Dipraktikan",
//     link: "/artikel_2",
//   },
//   {
//     src: require("../assets/images/artikel2.png"),
//     title: "Seperti Apa Sih BMI Ideal?",
//     link: "/image3",
//   },
// ];

// function ImageViewer() {
//   const [index, setIndex] = useState(0);
//   const navigate = useNavigate();

//   const handleSelect = (selectedIndex, e) => {
//     setIndex(selectedIndex);
//   };

//   const redirectToPage = () => {
//     console.log('navigasi kemana', images[index].link);
//     navigate(images[index].link);
//   };

//   return (
//     <div className="container">
//       <div className="row justify-content-center">
//         <div className="col-lg-12 text-center">
//           <Carousel
//             activeIndex={index}
//             onSelect={handleSelect}
//             interval={null}
//             indicators={false}
//             controls={false}
//           >
//             {images.map((image, idx) => (
//               <Carousel.Item key={idx}>
//                 <div>
//                   <img
//                     className="d-block w-100"
//                     src={image.src}
//                     alt={image.title}
//                     style={imgStyles}
//                   />
//                 </div>
//                 <Carousel.Caption style={carouselOption}>
//                   <h1>{image.title}</h1>
//                 </Carousel.Caption>
//               </Carousel.Item>
//             ))}
//             <div style={openButtonContainer}>
//               <button
//                 className="btn btn-light btn-lg d-flex align-items-center"
//                 style={openButtonStyle}
//                 onClick={redirectToPage}
//               >
//                 Buka
//                 <div style={circleStyle}>
//                   <ImArrowUpRight2 />
//                 </div>
//               </button>
//             </div>
//             <div className="row" style={carouselButton}>
//               <div className="d-flex justify-content-between">
//                 <button
//                   className="btn btn-outline-light transparent-button d-flex align-items-center"
//                   style={rightBorderRadius}
//                   onClick={() =>
//                     setIndex((prevIndex) =>
//                       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//                     )
//                   }
//                 >
//                   Prev
//                   <div style={circleCarouselLeftBtnStyle}>
//                     <AiOutlineLeft />
//                   </div>
//                 </button>
//                 <div style={paddingContent} />
//                 <button
//                   className="btn btn-outline-light transparent-button d-flex align-items-center"
//                   style={rightBorderRadius}
//                   onClick={() =>
//                     setIndex((prevIndex) => (prevIndex + 1) % images.length)
//                   }
//                 >
//                   <div style={circleCarouselRightBtnStyle}>
//                     <AiOutlineRight />
//                   </div>
//                   Next
//                 </button>
//               </div>
//             </div>
//           </Carousel>
//         </div>
//       </div>
//     </div>
//   );
// }

// const imgStyles = {
//   height: "650px",
//   objectFit: "cover",
//   borderRadius: "20px",
// };

// const carouselOption = {
//   position: "absolute",
//   left: "5%",
//   right: "50%",
//   bottom: "0%",
//   textAlign: "left",
//   transform: "none",
// };

// const rightBorderRadius = {
//   borderRadius: "20px",
// };
// const carouselButton = {
//   position: "absolute",
//   bottom: "5%",
//   right: "5%",
// };
// const openButtonContainer = {
//   position: "absolute",
//   top: "5%",
//   right: "5%",
// };
// const openButtonStyle = {
//   borderRadius: "30px",
// };
// const circleStyle = {
//   width: "25px",
//   height: "25px",
//   borderRadius: "50%",
//   border: "1px solid",
//   alignSelf: "center",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   marginLeft: "10px",
// };
// const circleCarouselRightBtnStyle = {
//   width: "25px",
//   height: "25px",
//   borderRadius: "50%",
//   border: "1px solid",
//   alignSelf: "center",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   marginRight: "5px",
// };
// const circleCarouselLeftBtnStyle = {
//   width: "25px",
//   height: "25px",
//   borderRadius: "50%",
//   border: "1px solid",
//   alignSelf: "center",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   marginLeft: "5px",
// };
// const paddingContent = {
//   padding: "5px",
// };

// export default ImageViewer;

import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

function ImageViewer() {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await fetch("https://api2.lyfe.my.id/articles/published"); // Ganti dengan URL API
        const data = await response.json();
        // Asumsikan data yang diterima memiliki struktur yang sesuai
        setImages(data); 
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run effect only once on mount

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const redirectToPage = () => {
    console.log('navigate kemana', images[index])
      const data_artikel = images[index];
      navigate('/artikel', { state: data_artikel });
  };

  if (images.length === 0) {
    return <div>Loading...</div>; // Loading state
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12 text-center">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={null}
            indicators={false}
            controls={false}
          >
            {images.map((image, idx) => (
              <Carousel.Item key={idx}>
                <div>
                  <img
                    className="d-block w-100"
                    src={`data:image/jpeg;base64,${image.image}`}
                    alt={image.title}
                    style={imgStyles}
                  />
                </div>
                <Carousel.Caption style={carouselOption}>
                  <h1>{image.title}</h1>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
            <div style={openButtonContainer}>
              <button
                className="btn btn-light btn-lg d-flex align-items-center"
                style={openButtonStyle}
                onClick={redirectToPage}
              >
                Buka
                <div style={circleStyle}>
                  <ImArrowUpRight2 />
                </div>
              </button>
            </div>
            <div className="row" style={carouselButton}>
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-outline-light transparent-button d-flex align-items-center"
                  style={rightBorderRadius}
                  onClick={() =>
                    setIndex((prevIndex) =>
                      prevIndex === 0 ? images.length - 1 : prevIndex - 1
                    )
                  }
                >
                  Prev
                  <div style={circleCarouselLeftBtnStyle}>
                    <AiOutlineLeft />
                  </div>
                </button>
                <div style={paddingContent} />
                <button
                  className="btn btn-outline-light transparent-button d-flex align-items-center"
                  style={rightBorderRadius}
                  onClick={() =>
                    setIndex((prevIndex) => (prevIndex + 1) % images.length)
                  }
                >
                  <div style={circleCarouselRightBtnStyle}>
                    <AiOutlineRight />
                  </div>
                  Next
                </button>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

// Styles
const imgStyles = {
  height: "650px",
  objectFit: "cover",
  borderRadius: "20px",
};

const carouselOption = {
  position: "absolute",
  left: "5%",
  right: "50%",
  bottom: "0%",
  textAlign: "left",
  transform: "none",
};

const rightBorderRadius = {
  borderRadius: "20px",
};
const carouselButton = {
  position: "absolute",
  bottom: "5%",
  right: "5%",
};
const openButtonContainer = {
  position: "absolute",
  top: "5%",
  right: "5%",
};
const openButtonStyle = {
  borderRadius: "30px",
};
const circleStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  border: "1px solid",
  alignSelf: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "10px",
};
const circleCarouselRightBtnStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  border: "1px solid",
  alignSelf: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "5px",
};
const circleCarouselLeftBtnStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  border: "1px solid",
  alignSelf: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "5px",
};
const paddingContent = {
  padding: "5px",
};

export default ImageViewer;
