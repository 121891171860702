import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api2.lyfe.my.id/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, newPassword }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                // Show success message and navigate to login
                alert(data.message); // Popup message
                navigate("/login"); // Redirect to login page
            } else {
                setMessage(data.message);
                alert(data.message); // Show error in popup
            }
        } catch (error) {
            console.error("Error:", error);
            setMessage("An error occurred. Please try again.");
            alert("An error occurred. Please try again."); // Show error in popup
        }
    };

    // Inline styles
    const formContainerStyle = {
        maxWidth: '400px',
        margin: 'auto',
        padding: '20px',
        borderRadius: '20px',
        backgroundColor: '#f8f9fa', // Light background color
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional shadow effect
    };

    return (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={formContainerStyle}>
                <h1 className="text-center mb-4">Reset Password</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3 w-100">
                        Reset Password
                    </Button>
                </Form>
                {message && <p className="text-center mt-3" style={{ color: 'red' }}>{message}</p>}
            </div>
        </Container>
    );
}

export default ResetPassword;
