import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Loginimg from "../assets/images/Group51.png";
import "../assets/customcss/logreg.css";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    // Validasi field
    if (!name) {
      newErrors.name = "Nama tidak boleh kosong";
    } else if (name.length < 1 || name.length > 30) {
      newErrors.name = "Nama harus terdiri dari 1 hingga 30 karakter";
    }
    if (!email) {
      newErrors.email = "Email tidak boleh kosong";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Format email tidak valid";
    } else if (email.length < 5 || email.length > 50) {
      newErrors.email = "Email harus terdiri dari 5 hingga 50 karakter";
    }
    if (!password) {
      newErrors.password = "Password tidak boleh kosong";
    } else if (password.length < 8 || password.length > 20) {
      newErrors.password = "Password harus terdiri dari 8 hingga 20 karakter";
    }
    // Jika ada error, set error dan return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    // Proses registrasi jika tidak ada error
    const response = await fetch("https://api2.lyfe.my.id/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password, role: 'user' }),
    });
    if (response.ok) {
      navigate("/login"); // Navigate to login after success alert
    } else {
      const data = await response.json();
      setErrorMessage(data.message || "Registrasi gagal. Silakan coba lagi.");
    }
  };

  return (
    <Container fluid className="d-flex align-items-center justify-content-center vh-100">
      <Row className="w-100 no-gutters">
        <Col lg={6} className="d-flex justify-content-center align-items-center">
          <div className="w-100">
            <img
              src={Loginimg}
              alt="Gambar"
              style={{
                objectFit: "cover",
                borderRadius: "20px",
                width: "100%",
                height: "650px",
              }}
            />
          </div>
        </Col>
        <Col lg={6} className="form-login d-flex justify-content-start align-items-center p-3">
          <div className="w-100 form-container mr-2" style={{ marginTop: "0px" }}>
            <h1 className="text-center mb-5 text-primary">Sign Up</h1>
            <Form className="m-3" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Nama</Form.Label>
                <Form.Control
                  className="input-login"
                  type="text"
                  placeholder="Masukkan Nama Anda"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrors((prev) => ({ ...prev, name: undefined })); // Clear error on change
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #000",
                    borderRadius: "0px",
                  }}
                />
                {errors.name && <div className="text-danger">{errors.name}</div>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="input-login"
                  type="email"
                  placeholder="Masukkan Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrors((prev) => ({ ...prev, email: undefined })); // Clear error on change
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #000",
                    borderRadius: "0px",
                  }}
                />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  className="input-login"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrors((prev) => ({ ...prev, password: undefined })); // Clear error on change
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #000",
                    borderRadius: "0px",
                  }}
                />
                {errors.password && <div className="text-danger">{errors.password}</div>}
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                style={{ height: "45px" }}
              >
                Registrasi
              </Button>
            </Form>
            <div className="px-3">
              <p className="text-primary text-center mt-3">
                Sudah Memiliki Akun? <Link to="/login">Login Disini</Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
