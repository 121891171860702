import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "aos/dist/aos.css";
import AOS from "aos";
import Navbar from "../Component/Navbar";
import Footer from "../Navigation/footer";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { useLocation } from 'react-router-dom';

const ArtikelScreen = () => {
  const location = useLocation();
  const data_artikel = location.state;

  const [actionsStatus, setActionsStatus] = useState({
    like: false,
    save: false,
    read: false,
    note: false,
  });

  useEffect(() => {
    AOS.init();
    const storedStatus = JSON.parse(localStorage.getItem(`actionsStatus_${data_artikel.id}`)) || {};
    setActionsStatus(storedStatus);
  }, [data_artikel.id]);

  const handleAction = (action) => {
    const updatedStatus = { ...actionsStatus, [action]: true };
    setActionsStatus(updatedStatus);
    localStorage.setItem(`actionsStatus_${data_artikel.id}`, JSON.stringify(updatedStatus));
  };

  return (
    <main>
      <Navbar />
      <div style={styles.headerContainer}>
        <div style={styles.headerTextContainer}>
          <h1 style={glossy}>{data_artikel.title}</h1>
        </div>
      </div>

      <div data-aos="fade-up" data-aos-duration="1000">
        <div
          style={{
            ...styles.imageContainer,
            backgroundImage: `url(data:image/jpeg;base64,${data_artikel.image})`,
          }}
        ></div>
      </div>

      <div style={styles.contentContainer}>
        <p style={styles.contentText}>{data_artikel.content}</p>
      </div>

      <div style={styles.actionsContainer} data-aos="fade-up" data-aos-duration="1000">
        <h4 style={styles.actionsHeader}>Aksi</h4>
        <div style={styles.actionsRow}>
          {["like", "save", "read", "note"].map((action) => (
            <div
              key={action}
              style={{
                ...styles.actionItem,
                backgroundColor: actionsStatus[action] ? 'green' : 'white',
                color: actionsStatus[action] ? 'white' : 'black',
              }}
              onClick={() => handleAction(action)}
            >
              <div style={styles.iconContainer}>
                {action === "like" && <ThumbUpOutlinedIcon sx={{ fontSize: 32 }} />}
                {action === "save" && <BookmarkBorderOutlinedIcon sx={{ fontSize: 32 }} />}
                {action === "read" && <CheckBoxOutlinedIcon sx={{ fontSize: 32 }} />}
                {action === "note" && <TextSnippetOutlinedIcon sx={{ fontSize: 32 }} />}
              </div>
              <h5 style={styles.actionText}>
                {action === "like" ? "Suka" : action === "save" ? "Simpan" : action === "read" ? "Tandai Dibaca" : "Catatan"}
              </h5>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </main>
  );
};

const styles = {
  headerContainer: {
    padding: '5rem 0',
    textAlign: 'center',
  },
  headerTextContainer: {
    fontSize: '2.5rem',
  },
  imageContainer: {
    height: '400px',
    backgroundPosition: '55% 85%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '0.5rem',
  },
  contentContainer: {
    marginTop: '2rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  },
  contentText: {
    textAlign: 'justify',
  },
  actionsContainer: {
    margin: '3rem 0',
    padding: '2rem',
    borderRadius: '0.5rem',
    backgroundColor: '#f8f9fa',
  },
  actionsHeader: {
    marginBottom: '1.5rem',
  },
  actionsRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionItem: {
    flex: '1',
    margin: '0 1rem',
    padding: '1rem',
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    border: '1px solid #ccc',
  },
  iconContainer: {
    marginRight: '0.5rem',
  },
  actionText: {
    fontSize: '1rem',
  },
};

const glossy = {
  color: 'transparent',
  background: 'linear-gradient(45deg, #000000, #000000)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontSize: '2.5rem',
  textShadow: '0 0 10px rgba(0, 0, 0, 0.5), 0 0 20px rgba(255, 255, 255, 0.3)',
};

export default ArtikelScreen;
